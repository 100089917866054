import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`)
}

const routes = [
  {
    path: '/auth/login/:token',
    name: 'TokenLogin',
    component: loadView('auth/TokenLogin'),
  },
  {
    path: '/auth/emailLogin',
    name: 'EmailLogin',
    component: loadView('auth/EmailLogin'),
  },
  {
    path: '/dashboard/:swayOfferId?',
    alias: '*',
    name: 'Dashboard',
    props: (route) => ({ swayOfferId: route.params.swayOfferId }),
    component: loadView('Dashboard'),
    beforeEnter(to, from, next) {
      if (!localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_KEY)) {
        window.location.href = 'https://getsway.com/'
        return
      }

      next()
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...routes],
})

export default router
