import axios from 'axios'
import router from './router.js'

const TOKEN_KEY = process.env.VUE_APP_AUTH_TOKEN_KEY

axios.interceptors.response.use(
  function (response) {
    const currentVersion = response.headers['client-sway-version'] || ''
    const currentVersionChunks = currentVersion.split('.')
    const localVersionChunks = process.env.APP_VERSION.split('.')

    if (localVersionChunks.length !== currentVersionChunks.length) return response

    for (let i = 0; i < localVersionChunks.length; i++) {
      if (parseInt(localVersionChunks[i]) < parseInt(currentVersionChunks[i])) {
        console.log('out of date')
        return response
      }
    }

    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)

const ApiService = {
  init(baseURL) {
    axios.defaults.baseURL = baseURL
    axios.defaults.headers.common['app-type'] = 'Sway Client'
    axios.defaults.headers.common['app-version'] = process.env.APP_VERSION
    if (localStorage.getItem(TOKEN_KEY)) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(TOKEN_KEY)}`
    }
  },

  login(accessToken) {
    localStorage.setItem(TOKEN_KEY, '' + accessToken)

    window.location.href = '/'
  },

  getLoginToken() {
    return localStorage.getItem(TOKEN_KEY)
  },

  getDecodedLoginToken() {
    if (!this.getLoginToken()) return
    return JSON.parse(atob(this.getLoginToken().split('.')[1]))
  },

  removeAuthHeader() {
    delete axios.defaults.headers.common['Authorization']
  },

  get(resource, config = undefined) {
    return axios.get(resource, config)
  },

  post(resource, data) {
    return axios.post(resource, data)
  },

  put(resource, data) {
    return axios.put(resource, data)
  },
}

export default ApiService
