import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueToast from 'vue-toast-notification'

import App from './App.vue'
import router from './router'
import ApiService from './apiService'

import 'vue-toast-notification/dist/theme-sugar.css'

Vue.use(VueMeta)
Vue.use(VueToast, {
  position: 'bottom',
})

Vue.config.productionTip = false

ApiService.init(process.env.VUE_APP_DIVOC_API_URL)

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
