<template>
  <div>
    <LfAppEnvironmentBanner v-if="bannerVisible" :environment="environment" :version="appVersion" />

    <router-view />
  </div>
</template>

<script>
import Vue from 'vue'

import { LfAppEnvironmentBanner } from 'lf-common'

export default Vue.extend({
  name: 'App',

  metaInfo: {
    title: 'Sway | spread the word about your fave companies',
  },

  components: {
    LfAppEnvironmentBanner,
  },

  computed: {
    appVersion() {
      return process.env.APP_VERSION
    },
    environment() {
      return process.env.VUE_APP_ENVIRONMENT
    },
    bannerVisible() {
      return process.env.VUE_APP_ENVIRONMENT === 'Staging'
    },
  },
})
</script>
